// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 获取当前的用户 GET /api/currentUser */
export async function currentUser(options?: { [key: string]: any }) {
  return request<{
    data: API.CurrentUser;
  }>('https://www.jawagroup.org/api/currentUser', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 退出登录接口 POST /api/login/outLogin */
export async function outLogin(options?: { [key: string]: any }) {
  return request<Record<string, any>>('https://www.jawagroup.org/api/login/outLogin', {
    method: 'POST',
    ...(options || {}),
  });
}

/** 登录接口 POST /api/login/account */
export async function login(body: API.LoginParams, options?: { [key: string]: any }) {
  return request<API.LoginResult>('https://www.jawagroup.org/api/login/account', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 此处后端没有提供注释 GET /api/notices */
export async function getNotices(options?: { [key: string]: any }) {
  return request<API.NoticeIconList>('/api/notices', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取规则列表 GET /api/rule */
export async function rule(
  params: {
    // query
    /** 当前的页码 */
    current?: number;
    /** 页面的容量 */
    pageSize?: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.RuleList>('/api/rule', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 更新规则 PUT /api/rule */
export async function updateRule(options?: { [key: string]: any }) {
  return request<API.RuleListItem>('/api/rule', {
    method: 'POST',
    data: {
      method: 'update',
      ...(options || {}),
    },
  });
}

/** 新建规则 POST /api/rule */
export async function addRule(options?: { [key: string]: any }) {
  return request<API.RuleListItem>('/api/rule', {
    method: 'POST',
    data: {
      method: 'post',
      ...(options || {}),
    },
  });
}

/** 删除规则 DELETE /api/rule */
export async function removeRule(options?: { [key: string]: any }) {
  return request<Record<string, any>>('/api/rule', {
    method: 'POST',
    data: {
      method: 'delete',
      ...(options || {}),
    },
  });
}

/** 更新护照信息接口 PUT /api/updatePassport */
export async function updatePassportInfo(passportName: string, passportNumber: string): Promise<void> {
  return request<void>('https://www.jawagroup.org/api/updatePassport', {
    method: 'POST',
    data: {
      method: 'update',  // 跟随你的风格，类似删除使用'delete'，更新则用'update'
      passport_name: passportName,
      passport_number: passportNumber,
    },
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
}

/** 获取留言列表 GET /api/comments */
// export const getComments = async (): Promise<API.Comment[]> => {
//   return request<API.Comment[]>('https://www.jawagroup.org/api/comments/list', {
//     method: 'GET',
//   });
// };

/** 获取留言列表 GET /api/comments/list */
export async function getComments(
  params: {
    /** 当前页码 */
    current?: number;
    /** 每页条数 */
    pageSize?: number;
  },
  options?: { [key: string]: any },
): Promise<API.CommentList> {
  return request<API.CommentList>('https://www.jawagroup.org/api/comments/list', {
    method: 'GET',
    params: {
      ...params,  // 将分页参数传递到请求中
    },
    ...(options || {}),  // 合并其他可选项
  });
}


/** 提交新留言 POST /api/comments */
export async function postComment(data: { title: string; content: string }) {
  return request('https://www.jawagroup.org/api/comments/add', {
    method: 'POST',
    data,  // 将 title 和 content 发送到后端
  });
}


/** 获取用户列表 GET /api/users/list */
// export const getUsers = async () => {
//   return request('https://www.jawagroup.org/api/users/list', {
//     method: 'GET',
//     headers: {
//       'Authorization': `Bearer ${localStorage.getItem('token')}`,  // 从 localStorage 获取 JWT 令牌
//     },
//   });
// };

export const getUsers = async (params: { 
  current?: number; 
  pageSize?: number; 
  group_id?: number;
  dormitory_group_id?: number;
  sortField?: string;   // 新增排序字段
  sortOrder?: string;   // 新增排序顺序 ('asc' 或 'desc')
  searchTerm?: string;  // 新增搜索关键字
}) => {
  return request('https://www.jawagroup.org/api/users/list', {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,  // 从 localStorage 获取 JWT 令牌
    },
    params: {
      current: params.current,    // 后端期望的分页参数
      pageSize: params.pageSize,  // 每页条数
      group_id: params.group_id,  // 如果提供了 group_id，则传递给后端；否则忽略该参数
      dormitory_group_id: params.dormitory_group_id,  // 如果提供了 dormitory_group_id，则传递给后端；否则忽略该参数
      sortField: params.sortField,  // 传递排序字段
      sortOrder: params.sortOrder,  // 传递排序顺序
      searchTerm: params.searchTerm,  // 传递搜索关键字（用户名或护照编号）
    },
  });
};

/** 更新用户信息 POST /api/users/update */
export const updateUser = async (userId: number, data: { passport_expiry: number; group_id: number; employee_number: string }) => {
  return request(`https://www.jawagroup.org/api/users/update/${userId}`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    },
    data,
  });
};


/** 获取分组列表 GET /api/groups/list */
export const getGroups = async (params: { current: number; pageSize: number }) => {
  return request('https://www.jawagroup.org/api/groups/list', {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,  // 从 localStorage 获取 JWT 令牌
    },
    params: {
      current: params.current,    // 当前页码
      pageSize: params.pageSize, // 每页条数
    },
  });
};

/** 更新分组信息 POST /api/groups/update */
export const updateGroup = async (groupId: number, group_name: string, description: string) => {
  return request(`https://www.jawagroup.org/api/groups/update/${groupId}`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    },
    data: { group_name, description },
  });

};

/** 新建分组 POST /api/groups/add */
export const addGroup = async (group_name: string, description: string) => {
  return request('https://www.jawagroup.org/api/groups/add', {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,  // 从 localStorage 获取 JWT 令牌
    },
    data: { group_name, description },
  });
};

/** 删除分组 POST /api/groups/delete */
export const deleteGroup = async (group_id: number) => {
  return request('https://www.jawagroup.org/api/groups/delete', {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,  // 从 localStorage 获取 JWT 令牌
    },
    data: { group_id },
  });
};

/** 获取分组列表 GET /api/groups/list */
export const getDormitoryGroups = async (params: { current: number; pageSize: number }) => {
  return request('https://www.jawagroup.org/api/dormitory-groups/list', {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,  // 从 localStorage 获取 JWT 令牌
    },
    params: {
      current: params.current,    // 当前页码
      pageSize: params.pageSize, // 每页条数
    },
  });
};

/** 更新分组信息 POST /api/groups/update */
export const updateDormitoryGroup = async (groupId: number, group_name: string, description: string) => {
  return request(`https://www.jawagroup.org/api/dormitory-groups/update/${groupId}`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    },
    data: { group_name, description },
  });

};

/** 新建分组 POST /api/groups/add */
export const addDormitoryGroup = async (group_name: string, description: string) => {
  return request('https://www.jawagroup.org/api/dormitory-groups/add', {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,  // 从 localStorage 获取 JWT 令牌
    },
    data: { group_name, description },
  });
};

/** 删除分组 POST /api/groups/delete */
export const deleteDormitoryGroup = async (group_id: number) => {
  return request('https://www.jawagroup.org/api/dormitory-groups/delete', {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,  // 从 localStorage 获取 JWT 令牌
    },
    data: { group_id },
  });
};

/** 删除用户 DELETE /api/users/delete/:userId */
// export const deleteUser = async (userId: number) => {
//   return request(`https://www.jawagroup.org/api/users/delete/${userId}`, {
//     method: 'DELETE',
//     headers: {
//       'Authorization': `Bearer ${localStorage.getItem('token')}`,
//     },
//   });
// };

export async function deleteUser(userId: number, body: { password: string }, options?: { [key: string]: any }) {
  return request(`https://www.jawagroup.org/api/users/delete/${userId}`, {
    method: 'POST',  // 使用 POST 方法以便发送数据
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'application/json',
    },
    data: body,  // 将密码数据作为请求体
    ...(options || {}),  // 允许传递额外的选项
  });
}

/** 获取用户操作记录 GET /api/users/history/:userId */
export const getUserHistory = async (userId: number) => {
  return request(`https://www.jawagroup.org/api/users/history/${userId}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    },
  });
};

/** 导出用户数据 GET /api/users/export */
// export const exportUsers = async (params: { searchTerm: string; group_id: number | null }) => {
//   return request('https://www.jawagroup.org/api/users/export', {
//     method: 'GET',
//     headers: {
//       'Authorization': `Bearer ${localStorage.getItem('token')}`,  // 从 localStorage 获取 JWT 令牌
//     },
//     params: {
//       searchTerm: params.searchTerm,  // 搜索关键字
//       group_id: params.group_id,      // 分组ID
//     },
//   });
// };

/** 导出用户数据 GET /api/users/export */
export const exportUsers = async (params: { searchTerm: string; group_id: number | null }) => {
  try {
    // 调用接口，传递查询参数并获取二进制响应
    const response = await request('https://www.jawagroup.org/api/users/export', {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,  // 从 localStorage 获取 JWT 令牌
      },
      params: {
        searchTerm: params.searchTerm,  // 搜索关键字
        group_id: params.group_id,      // 分组ID
      },
      responseType: 'blob',  // 确保响应为二进制数据
    });

    return response;  // 返回响应的 Blob 对象
  } catch (error) {
    console.error('Error exporting users:', error);
    throw error;  // 将错误抛出，以便前端可以处理
  }
};

// { records: API.LogRecord[]; total: number }
// 获取日志列表的接口定义
export const getLogs = async (
  params: { page: number; pageSize: number }
): Promise<{ data: API.LogRecord[]; total: number }> => {
  return request<{ data: API.LogRecord[]; total: number }>(
    'https://www.jawagroup.org/api/logs',
    {
      method: 'GET',
      params: {
        current: params.page,      // 当前页码
        pageSize: params.pageSize, // 每页记录数
      },
    }
  );
};

// 获取最新公告
export async function getAnnouncement() {
  return request('https://www.jawagroup.org/api/announcements/latest', {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,  // 使用 JWT Token
    },
  });
}

export async function postAnnouncement(content: string) {
  return request('https://www.jawagroup.org/api/announcements/publish', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`, // 假设使用 localStorage 存储 JWT
    },
    data: { content },
  });
}

/** 获取规则列表 GET /api/rule */
export async function group_change(
  params: {
    // query
    /** 当前的页码 */
    current?: number;
    /** 页面的容量 */
    pageSize?: number;
    searchTerm?: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.RuleList>('https://www.jawagroup.org/api/group/logs', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

export async function card_change(
  params: {
    // query
    /** 当前的页码 */
    current?: number;
    /** 页面的容量 */
    pageSize?: number;
    searchTerm?: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.RuleList>('https://www.jawagroup.org/api/card/logs', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}